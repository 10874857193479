define('due-dashboard/models/verbatim-list', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    auto_tagged: _emberData['default'].attr('boolean'),
    date: _emberData['default'].attr('date'),
    email: _emberData['default'].attr('string'),
    language_tag: _emberData['default'].attr('string'),
    original_text: _emberData['default'].attr('string'),
    profile: _emberData['default'].attr('string'),
    resolved: _emberData['default'].attr('boolean'),
    segments: _emberData['default'].attr(),
    score: _emberData['default'].attr('number'),
    source_zone: _emberData['default'].attr('string'),
    survey: _emberData['default'].attr(),
    tags: _emberData['default'].attr(),
    text: _emberData['default'].attr('string'),

    rating_scale_type: _emberData['default'].attr('string'),
    survey_auto_tagging_enabled: _emberData['default'].attr('boolean')
  });
});