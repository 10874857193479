define('due-dashboard/components/reports/verbatims-list/show-item', ['exports', 'ember', 'moment', 'due-dashboard/helpers/tag-display-name'], function (exports, _ember, _moment, _dueDashboardHelpersTagDisplayName) {
  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var bind = _ember['default'].run.bind;
  var RSVP = _ember['default'].RSVP;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({

    tagManager: _ember['default'].inject.service(),

    tagName: 'div',
    classNames: ['verbatim-item'],
    classNameBindings: ['verbatim.profile'],
    translationKey: 'show_original',
    originalTextDisplay: false,
    showTranslation: false,
    showAllSegments: false,
    resolvedIcon: 'tio-inbox',
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    triggerDropDownReset: false,
    flatLevelDisplay: false,
    isReadOnly: computed.alias('currentAccount.isReadOnly'),
    autoTagsRights: computed.alias('currentAccount.canUseAutoTags'),
    triggerClientX: 0,
    triggerClientY: 0,
    triggerHeight: 0,

    init: function init() {
      var _this = this;

      this.get('tagManager').getAll({ survey: this.get('verbatim.survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }).then(function (tags) {
        if (!_this.get('isDestroyed')) {
          _this.set('tags', tags);
        }
      });
      $(window).on('resize', bind(this, this._setTriggerClientX));
      this._super.apply(this, arguments);
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setTriggerClientX();
    },

    noManualTags: computed('verbatim.tags', function () {
      var fdbTags = this.get('verbatim.tags') || [];
      return !fdbTags.any(function (ft) {
        return !ft.from_due;
      });
    }),

    noLiveAutoTags: computed('verbatim.tags', function () {
      var fdbTags = this.get('verbatim.tags') || [];
      return !fdbTags.any(function (ft) {
        return ft.from_due;
      });
    }),

    fromTrustpilot: computed('verbatim.rating_scale_type', function () {
      return this.get('verbatim.rating_scale_type') == "trustpilot_review" ? true : false;
    }),

    hideTagDropdown: computed('currentAccount', function () {
      var ca = this.get('currentAccount');
      return !ca.get('canUseTags') && !ca.get('canUseAutoTags') || this.get('verbatim.source_zone') === 'china' && !this.get('currentAccount.isFromChina') || this.get('isReadOnly');
    }),

    canUseTags: computed('currentAccount.canUseTags', 'currentAccount.isFromChina', 'verbatim.source_zone', function () {
      return this.get('currentAccount.canUseTags') && (this.get('verbatim.source_zone') !== 'china' && !this.get('currentAccount.isFromChina') || this.get('currentAccount.isFromChina'));
    }),

    canUseAutoTags: computed('currentAccount.canUseAutoTags', 'currentAccount.isFromChina', 'verbatim.source_zone', function () {
      return this.get('currentAccount.canUseAutoTags') && (this.get('verbatim.source_zone') !== 'china' && !this.get('currentAccount.isFromChina') || this.get('currentAccount.isFromChina'));
    }),
    canSeeTags: computed.alias('currentAccount.canSeeTags'),

    text: computed('verbatim', function () {
      var str = this.get('verbatim.text') ? this.get('verbatim.text') : this.get('verbatim.original_text');
      return str;
    }),

    displayText: computed('text', function () {
      var searchValue = this.get('feedbackSearchText');
      var feedbackSearchText = this.get('feedbackSearchText');

      if (feedbackSearchText.length > 0) {
        return this.highlightSearchInVerbatim(searchValue, this.get('text'));
      } else {
        return this.get('text');
      }
    }),

    highlightSearchInVerbatim: function highlightSearchInVerbatim(searchValue, comment) {
      var lowerSearch = searchValue.toLowerCase();
      var lowerComment = comment.toLowerCase();
      var regex = new RegExp(searchValue, 'gi');
      var match = comment.match(regex);

      if (lowerComment.includes(lowerSearch) && match) {
        var originalText = match[0];
        var highlightedText = comment.replace(regex, '<span class="highlighted-search">' + originalText + '</span>');
        return htmlSafe(highlightedText);
      }
      return comment;
    },

    isTranslation: computed('verbatim', 'i18n.locale', 'language', 'verbatimTranslation', function () {
      if (this.get('verbatim.language_tag')) {
        return this.get('verbatim.language_tag').toLowerCase() !== this.get('i18n.locale');
      }
      return false;
    }),

    forceOpenTranslation: computed('isTranslation', function () {
      var isTranslation = this.get('isTranslation');
      var searchValue = this.get('feedbackSearchText');
      var originalText = this.get('verbatim.original_text');
      var searchLanguageKey = this.get('searchLanguageFilter.key');
      var forceOpen = isTranslation && searchValue.length > 0 && searchLanguageKey === 'original_language' && originalText.toLowerCase().includes(searchValue.toLowerCase());
      if (forceOpen) {
        this.set('translationKey', 'hide_original');
        this.set('showTranslation', true);
      }
      return forceOpen;
    }),

    originalText: computed('verbatim.comment', function () {
      var searchValue = this.get('feedbackSearchText');
      var text = this.get('verbatim.original_text');
      var feedbackSearchText = this.get('feedbackSearchText');

      if (feedbackSearchText.length > 0) {
        return this.highlightSearchInVerbatim(searchValue, text);
      } else {
        return text;
      }
    }),

    resolvedText: computed('verbatim', 'verbatim.resolved', function () {
      if (this.get('verbatim.resolved')) {
        this.set('resolvedIcon', 'tio-checkmark_circle');
        this.set('resolvedClass', 'resolved');
        return this.get('i18n').t('chats.resolved');
      }
      this.set('resolvedIcon', 'tio-inbox');
      this.set('resolvedClass', 'unresolved');
      return this.get('i18n').t('sidebar.filters.resolved.unresolved');
    }),

    verbatimSegments: computed('verbatim.segments', 'showAllSegments', function () {
      var segments = this.get('verbatim.segments');

      return Object.keys(segments || []).map(function (segmentName) {
        return { name: segmentName, value: segments[segmentName] };
      });
    }),

    verbatimSegmentsToDisplay: computed('verbatim.segments', 'showAllSegments', function () {
      var segments = this.get('verbatimSegments');

      if (!this.get('showAllSegments')) {
        return segments.slice(0, 3);
      }
      return segments;
    }),

    showAllSegmentsText: computed('showAllSegments', 'verbatim.company_segment_values', function () {
      if (!this.get('showAllSegments')) {
        return this.get('i18n').t('verbatims.show_more', { count: this.get('verbatimSegments.length') });
      } else {
        return this.get('i18n').t('verbatims.show_less');
      }
    }),

    verbatimDateTime: computed('verbatim.date', function () {
      return (0, _moment['default'])(this.get('verbatim.date')).format('LLL');
    }),

    realTags: computed('tags', 'verbatim.tags', 'verbatim.id', function () {
      var _this2 = this;

      var res = [],
          tags = this.get('tags'),
          flatLevelDisplay = this.get('flatLevelDisplay'),
          selectedTags = this.get('verbatim.tags');
      if (tags !== undefined && flatLevelDisplay === false && !this.get('isLoading')) {
        tags.forEach(function (tag, _, array) {
          _this2._computeTags(array, tag, res, selectedTags);
        });
      } else if (tags !== undefined && !this.get('isLoading')) {
        tags.forEach(function (tag) {
          return _this2._buildTagsFlatLevelRows(tag, res, selectedTags);
        });
      }
      return res;
    }),

    _buildTagsFlatLevelRows: function _buildTagsFlatLevelRows(tag, res, selectedTags) {
      tag.set('title', _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')));
      tag.set('disabled', selectedTags.filter(function (selected) {
        return selected.get('name') == tag.get('name');
      }).length !== 0);
      res.push(tag);
    },

    _computeTags: function _computeTags(tags, tag, res, selectedTags) {
      var _this3 = this;

      if (!tag.get('name')) {
        return;
      }
      var splitName = tag.get('name').split(' > ');
      var splitTitle = _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')).split('>');
      var depth = splitName.length - 1;
      tag.set('depth', splitName.length - 1);
      tag.set('displayName', splitName[tag.get('depth')]);
      tag.set('title', splitTitle[depth]);
      var subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item.get('name').match(subTagRegExp);
      });
      subTags = subTags.map(function (subTag) {
        _this3._computeTags(tags, subTag, res, selectedTags);
        subTag.set('parentTag', tag);
        return subTag;
      });

      var disabled = selectedTags.find(function (selected) {
        return selected.name === tag.get('name');
      }) !== undefined || tag.get('not_assignable');
      if (tag.get('due_exclusive') || this.get('autoTagsRights')) {
        disabled = !this.get('autoTagsRights') ? true : disabled; // cannot assign tag if read only right
      }
      if (subTags.length > 0) {
        if (!tag.get('not_assignable')) {
          var generalTag = {
            title: 'General', name: 'general', type: 'general',
            realTag: tag, must_be_first: true, disabled: disabled,
            feeling_enabled: tag.get('feeling_enabled')
          };
          subTags.unshiftObject(generalTag);
        }
        tag.set('options', subTags);
        tag.set('groupName', { title: tag.get('title'), name: tag.get('displayName'), type: 'tag' });
      } else {
        tag.set('disabled', disabled);
      }

      if (depth === 0) res.push(tag);
    },

    _setTriggerClientX: function _setTriggerClientX() {
      if (this.element) {
        var trigger = this.element.querySelector('.trigger-btn');
        if (trigger) {
          this.set('triggerClientY', trigger.offsetTop);
          this.set('triggerClientX', trigger.getBoundingClientRect().x);
          this.set('triggerHeight', trigger.getBoundingClientRect().height);
        }
      }
    },

    actions: {
      /* Search tags from tag component input */
      searchTags: function searchTags(term) {
        var _this4 = this;

        this.set('flatLevelDisplay', term !== '');
        this.get('tagManager').getAll({ name: term, survey: this.get('verbatim.survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }).then(function (tags) {
          if (!_this4.get('isDestroyed')) _this4.set('tags', tags);
        });
      },

      /* Select tag from tag component */
      selectTag: function selectTag(tagObject, feeling) {
        var _this5 = this;

        var tag = tagObject.realTag || tagObject;
        this.store.findRecord('feedback', this.get('verbatim.id')).then(function (feedback) {
          var feedback_tag = _this5.store.createRecord('feedback-tag', {
            tag: tag,
            feedback: feedback,
            feeling: feeling
          });
          feedback_tag.save();
        });
        this.get('verbatim.tags').pushObject(tag);
      },

      /* Reset tag after closing dropdown */
      closeDropdown: function closeDropdown() {
        var _this6 = this;

        this.get('tagManager').getAll({ survey: this.get('verbatim.survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }).then(function (tags) {
          if (!_this6.get('isDestroyed')) _this6.set('tags', tags);
        });
      },

      toggleDisplayText: function toggleDisplayText() {
        this.toggleProperty('showTranslation');
        this.set('forceOpenTranslation', false);
        if (!this.get('showTranslation')) {
          this.set('translationKey', 'show_original');
        } else {
          this.set('forceOpenTranslation', false);
          this.set('translationKey', 'hide_original');
        }
      },

      removeTagAction: function removeTagAction(tag) {
        var _this7 = this;

        this.store.query('feedback-tag', { filter: { tag_id: tag.id, feedback_id: this.get('verbatim.id') } }).then(function (fdbTags) {
          var promises = [];
          fdbTags.forEach(function (fdbTag) {
            promises.push(fdbTag.destroyRecord());
          });
          RSVP.all(promises).then(function () {
            _this7.get('verbatim.tags').removeObject(tag);
            _this7.notifyPropertyChange('verbatim.tags');
            _this7.notifyPropertyChange('verbatim.feedback_tags');
          });
        });
      },

      toggleShowAllSegment: function toggleShowAllSegment() {
        this.toggleProperty('showAllSegments');
      }
    }
  });
});