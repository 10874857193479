define('due-dashboard/components/reports/-verbatims-list', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/thousand-separator'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersThousandSeparator) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-verbatims-list'],
    classNameBindings: ['layout'],

    store: service(),
    globalFilters: service('feedback-search/global'),

    isLoading: true,
    isEmpty: false,
    verbatims: [],
    feedbackSearchText: '',

    hideEmail: computed.alias('currentAccount.content.company.hide_email_in_export'),

    progressModalIsOpened: false,
    settingsDropdownIsOpen: false,
    settingsDropdownOptions: computed(function () {
      return [{ title: this.get('i18n').t('words.sort_by'), key: 'sort', currentItem: 0, options: this.get('sortOptions') }, { title: this.get('i18n').t('words.search_language'), key: 'searchLanguage', currentItem: 0, options: this.get('searchLanguageOptions') }];
    }),

    exportModalIsOpened: false,
    availableExportFormats: ['pdf', 'xlsx'],
    customExportOptions: computed('hideEmail', function () {
      var hideEmail = this.get('hideEmail');
      var opts = [];

      if (!hideEmail) opts.push({ selected: true, key: 'email', label: this.get('i18n').t('words.email_address').string });

      return opts.concat([{ selected: true, key: 'segments', label: this.get('i18n').t('words.segments').string }, { selected: true, key: 'tags', label: this.get('i18n').t('words.tags').string }, { selected: true, key: 'resolved', label: this.get('i18n').t('words.status').string }, { selected: true, key: 'original_comment', label: this.get('i18n').t('words.original_comment').string }]);
    }),

    init: function init() {
      this._super();
      this._queryVerbatims();
    },

    _queryVerbatims: function _queryVerbatims() {
      var _this = this;

      var page_size = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];
      var page_number = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

      if (this.get('canView')) {
        var _service = this.get('filters'),
            global_filters = this.get('globalFilters.current'),
            report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
            component_filters = _.thru(this.get('content.component.filters'), function (raw) {
          var legacy = typeof raw === 'object' ? raw : { surveys: raw };

          return _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(_this.container, legacy);
        }),
            merged_filters = global_filters.merge(report_filters, component_filters),
            query = { filter: merged_filters.toAPIFormat() };

        query.filter.search_language = this.get('searchLanguage').key;
        query.filter.search_keyword = this.get('feedbackSearchText');
        query.page = { number: page_number, size: page_size };
        query.sort = this.get('sort').key;

        var promise = this.get('store').query('verbatim-list', query);
        this.set('isLoading', true);

        promise.then(function (verbatims) {
          var meta = verbatims.get('meta');
          _this.set('current_page', meta.current_page);
          _this.set('next_page', meta.next_page);
          _this.set('page_count', meta.page_count);
          _this.set('total_count', _dueDashboardHelpersThousandSeparator['default'].compute(meta.total_count));
          _this.set('verbatims', verbatims.toArray());

          _this.set('isLoading', false);
          _this.set('isEmpty', verbatims.toArray().length === 0);
        })['catch'](function (error) {
          _this.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    },

    sortOptions: computed(function () {
      return [{ key: 'most_recent', text: this.get('i18n').t('components.reports.array.sorts.most_recent') }, { key: 'comment_size', text: this.get('i18n').t('components.reports.array.sorts.comment_size') }];
    }),
    sort: computed(function () {
      return { key: 'most_recent' };
    }),

    searchLanguageOptions: computed(function () {
      return [{ key: 'translated_language', text: this.get('i18n').t('words.translated_verbatim') + ' (' + this.get('currentAccount.content.language.tag') + ')' }, { key: 'original_language', text: this.get('i18n').t('words.original_verbatim') }];
    }),
    searchLanguage: computed(function () {
      return { key: 'translated_language' };
    }),

    limits: computed(function () {
      return [{ key: 10, text: '10' }, { key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    limit: { key: 50, text: '50' },

    pageIndex: 0,

    page: computed('pages', 'limit', 'pageIndex', function () {
      var pages = this.get('pages'),
          pageIndex = this.get('pageIndex');

      return pages[pageIndex];
    }),

    leftArrowDisabled: computed('current_page', 'pages', function () {
      return this.get('current_page') === 1;
    }),

    rightArrowDisabled: computed('current_page', 'pages', function () {
      return this.get('current_page') === this.get('page_count');
    }),

    nbLeftColumns: computed('page', function () {
      return this.get('page').leftSideContent[0].length;
    }),

    pages: computed('limit', 'content', 'verbatims', function () {
      var verbatims = this.get('verbatims'),
          limit = this.get('limit').key,
          finalContent = [[]];
      var pageIndex = 0;

      verbatims.forEach(function (verbatim, index) {
        finalContent[pageIndex].push(verbatim);
        if (limit !== -1 && (index + 1) % limit === 0 && index !== 0 && index !== verbatims.length - 1) {
          pageIndex += 1;
          finalContent.push([]);
        }
      });
      return finalContent;
    }),

    title: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].title;
    }),

    _scrollToTop: function _scrollToTop() {
      this.element.scrollIntoView(true, { behavior: "smooth" });
    },

    _closeProgressModal: function _closeProgressModal() {
      this.set('progressModalIsOpened', false);
    },

    reloadObserver: observer('reload', 'globalFilters.current.tags.[]', function () {
      var _this2 = this;

      _ember['default'].run.next(function (_) {
        return _this2._queryVerbatims();
      });
    }),

    actions: {
      changePagination: function changePagination(selected) {
        this.set('limit', selected);
        this._queryVerbatims(selected.key, 0);
        this._scrollToTop();
      },

      previousPage: function previousPage() {
        this._queryVerbatims(this.get('limit').key, this.get('current_page') - 1);
        this._scrollToTop();
      },

      nextPage: function nextPage() {
        this._queryVerbatims(this.get('limit').key, this.get('current_page') + 1);
        this._scrollToTop();
      },

      sortVerbatims: function sortVerbatims(selected) {
        this.set('sort', selected);
      },

      onSelectOption: function onSelectOption(selectedSetting) {
        var searchValue = this.get('feedbackSearchText');

        if (this.get('current_page') !== 1) this.set('current_page', 1);
        this.set(selectedSetting.groupKey, { key: selectedSetting.key });
        this.set('settingsDropdownOptions.' + selectedSetting.groupIndex + '.currentItem', selectedSetting.index);
        if (selectedSetting.groupKey === 'sort' || searchValue.length > 0) this._queryVerbatims(this.get('limit').key, this.get('current_page'));
      },

      debouncedSearchText: function debouncedSearchText() {
        if (this.get('settingsDropdownIsOpen')) this.set('settingsDropdownIsOpen', false);
        if (this.get('current_page') !== 1) this.set('current_page', 1);
        _ember['default'].run.debounce(this, this._queryVerbatims, this.get('limit').key, this.get('current_page'), 300);
      },

      toggleSettingsDropdownState: function toggleSettingsDropdownState() {
        this.toggleProperty('settingsDropdownIsOpen');
      },

      openExportModal: function openExportModal() {
        this.set('exportModalIsOpened', true);
      },

      closeExportModal: function closeExportModal() {
        this.set('exportModalIsOpened', false);
      },

      closeProgressModal: function closeProgressModal() {
        this._closeProgressModal();
      },

      validateExport: function validateExport(exportConfig) {
        var _this3 = this;

        var search_language = this.get('searchLanguage').key;
        var search_keyword = this.get('feedbackSearchText');
        var sort = this.get('sort').key;
        var global_filters = this.get('globalFilters.current'),
            report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
            component_filters = _.thru(this.get('content.component.filters'), function (raw) {
          var legacy = typeof raw === 'object' ? raw : { surveys: raw };

          return _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(_this3.container, legacy);
        }),
            merged_filters = global_filters.merge(report_filters, component_filters),
            query = { filter: merged_filters.toAPIFormat() };

        query.filter.search_language = search_language;
        query.filter.search_keyword = search_keyword;

        var stringContent = JSON.stringify({ filter: query.filter, sort: sort });
        var promise = this.get('store').createRecord('verbatim-export-request', {
          export_type: exportConfig.export_format,
          file_file_name: exportConfig.file_name,
          content: stringContent,
          content_options: exportConfig.custom_columns_list.map(function (opt) {
            return opt.selected ? opt.key : null;
          }).filter(function (x) {
            return x;
          })
        }).save();

        this.set('export_promise', promise);
        promise.then(function () {
          _this3.send('closeExportModal');
          _this3.set('progressModalIsOpened', true);
        });
      },

      goToHistory: function goToHistory() {
        this.get('redirectAction')('companies.exports.history');
        this._closeProgressModal();
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report/-array}} array component
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */